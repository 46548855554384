<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="table-header">
      Cantidad de Proveedores: <b>{{ providers.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :scrollable="true"
      :data="providers"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      :checked-rows.sync="checkedProviders"
      :current-page="currentPage"
      :selected.sync="selected"
      ref="Providers"
      paginated
      :per-page="perPage"
      :opened-detailed="defaultOpenedDetails"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Información de ${row.NOMBRE_PRV}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
      checkable
      checkbox-position="left"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      default-sort="CLAVE_PRV"
    >
      <template #empty>
        <div class="has-text-centered">No hay proveedores</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          :visible="column.display"
          v-bind="column"
          :key="column.field"
          :label="column.label"
        >
          <span v-if="column.money && checkIfValid(props.row[column.field])">{{
            props.row[column.field] | money("MXN", 2)
          }}</span>
          <span v-if="!column.money && checkIfValid(props.row[column.field])">{{
            props.row[column.field]
          }}</span>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="media-content">
            <div class="content">
              <b-button type="is-primary" @click="showBalanceData(props.row)"
                >Ver detalles del saldo</b-button
              >
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import Cleave from "cleave.js";
import moment from "moment";
import Masks from "../../../data/cleave-masks";
import BalanceDetailsTabs from "./BalanceDetailsTabs";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "ProvidersBalances",
  directives: { cleave },
  data() {
    //Proveedores
    return {
      perPage: 50,
      checkedProviders: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_PRV",
          label: "Clave del proveedor",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortyByKey,
        },
        {
          field: "NOMBRE_PRV",
          label: "Nombre",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DOMICIL_PRV",
          label: "Domicilio",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "LIMITE_CRD",
          label: "Limite de crédito",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "BALANCE_FIN",
          label: "Estado de cuenta actual",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
      masks: Masks,
      refreshLoading: false,
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {},
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));
      allPromises.push(this.$store.dispatch("GETBANKS"));
      allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
      allPromises.push(
        this.$store.dispatch("GETEXPENSES", {
          startDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 7
          ),
          endDate: new Date(),
        })
      );
      allPromises.push(this.$store.dispatch("GETCLIENTS"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    showBalanceData(balanceInformation) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: BalanceDetailsTabs,
        props: {
          objectInformation: balanceInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    sortyByKey(a, b, isAsc) {
      if (isAsc) {
        return a.CLAVE_PRV.localeCompare(b.CLAVE_PRV, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      } else {
        return b.CLAVE_PRV.localeCompare(a.CLAVE_PRV, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      }
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        let providerWithProps = { ...singleProvider };
        providerWithProps.soonPaymentActive = false;
        providerWithProps.creditActive = false;
        providerWithProps.cardActive = false;
        if (!singleProvider.TIPO_PRV) {
          providerWithProps.providerType = "Proveedor";
        } else {
          providerWithProps.providerType =
            singleProvider.TIPO_PRV === "P" ? "Proveedor" : "Acreedores";
        }
        return providerWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
